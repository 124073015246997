const QUESTIONS_TYPE = [
  {
    key: '0',
    text: 'Any Type',
    value: '0'
  },
  {
    key: 'multiple',
    text: 'Multiple Choice',
    value: 'multiple'
  },
  {
    key: 'boolean',
    text: 'True / False',
    value: 'boolean'
  }
];

export default QUESTIONS_TYPE;
