import React from 'react';
import { Container, Message, Icon } from 'semantic-ui-react';

const Loader = () => {
  return (
    <Container className='ui inverted green'>
      <Message color='green' className='ui inverted green' icon size="big">
        <Icon inverted color='green' name="circle notched" loading />
        <Message.Content>
          <Message.Header><h3 class="ui header inverted green"> Just one second</h3></Message.Header>
          <h3 class="ui header inverted green"> We are fetching that content for you.</h3>
        </Message.Content>
      </Message>
    </Container>
  );
};

export default Loader;
