import React, { useState } from 'react';
import { SocketContext, socketConnection } from '../Socketio/socketio'
import { Segment, Grid, Embed } from 'semantic-ui-react'

import Layout from '../Layout';
import Loader from '../Loader';
import Main from '../Main';
import Quiz from '../Quiz';
import Result from '../Result';

import { shuffle } from '../../utils';
const openaichaturl = window._env_.REACT_APP_OPENAICHAT

const App = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [countdownTime, setCountdownTime] = useState(null);
  const [isQuizStarted, setIsQuizStarted] = useState(false);
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [resultData, setResultData] = useState(null);
  // const openaichaturl = window._env_.REACT_APP_OPENAICHAT || "http://localhost:5173";
  // const openaichaturl = "http://localhost:5173";

  const startQuiz = (data, countdownTime) => {
    setLoading(true);
    setCountdownTime(countdownTime);

    setTimeout(() => {
      setData(data);
      setIsQuizStarted(true);
      setLoading(false);
    }, 1000);
  };

  const endQuiz = resultData => {
    setLoading(true);

    setTimeout(() => {
      setIsQuizStarted(false);
      setIsQuizCompleted(true);
      setResultData(resultData);
      setLoading(false);
    }, 2000);
  };

  const replayQuiz = () => {
    setLoading(true);

    const shuffledData = shuffle(data);
    shuffledData.forEach(element => {
      element.options = shuffle(element.options);
    });

    setData(shuffledData);

    setTimeout(() => {
      setIsQuizStarted(true);
      setIsQuizCompleted(false);
      setResultData(null);
      setLoading(false);
    }, 1000);
  };

  const resetQuiz = () => {
    setLoading(true);

    setTimeout(() => {
      setData(null);
      setCountdownTime(null);
      setIsQuizStarted(false);
      setIsQuizCompleted(false);
      setResultData(null);
      setLoading(false);
    }, 1000);
  };

  return (
    <SocketContext.Provider value={socketConnection}>
    <Grid columns={2} stackable>
    <Grid.Column style={{ minHeight: '100vh' }}>
    <Layout>
      {loading && <Loader />}
      {!loading && !isQuizStarted && !isQuizCompleted && (
        <Segment inverted>
          <Main startQuiz={startQuiz} />
        </Segment>
      )}
      {!loading && isQuizStarted && (
        <Quiz data={data} countdownTime={countdownTime} endQuiz={endQuiz} />
      )}
      {!loading && isQuizCompleted && (
        <Result {...resultData} replayQuiz={replayQuiz} resetQuiz={resetQuiz} />
      )}
    </Layout>
    </Grid.Column>
    <Grid.Column style={{ minHeight: '100vh' }}>
    <iframe width="100%" height="100%" src={ openaichaturl }></iframe>
    </Grid.Column>
    </Grid>
    </SocketContext.Provider>
  );
};

export default App;
